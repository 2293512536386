import AuthProvider from "components/context/AuthContext";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import Investor from "./pages/Investor";
import Policies from "./pages/privacy-policies";
import Privacy from "./pages/policies";
import Media from "./pages/media";
import Toaster from "components/Toaster";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

import {
  createBrowserRouter,
  Route,
  Router,
  RouterProvider,
  Routes,
} from "react-router-dom";
import TwitterCallback from "pages/twitter/TwitterCallback";
import ToastCard from "components/ToastCard";

function App() {
  ReactGA.initialize("G-TBFMDLLFSY");

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
    },
        {
      path: "/ir",
      element: <Investor />,
    },
            {
      path: "/privacy-policy",
      element: <Policies />,
    },
                {
      path: "/policies",
      element: <Privacy />,
    },
      {
      path: "/media",
      element: <Media />,
    },
    {
      path: "/auth/twitter",
      element: <TwitterCallback />,
    },
  ]);

  return (
    <GoogleOAuthProvider clientId="385864264171-luiesi7am0rdcs393bgqqnacijlqtsse.apps.googleusercontent.com">
      <AuthProvider>
        <SnackbarProvider
          maxSnack={5}
          hideIconVariant
          preventDuplicate
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          iconVariant={{
            success: "✅",
            error: "✖️",
            warning: "⚠️",
            info: "ℹ️",
          }}
        >
          <RouterProvider router={router} />
          <Toaster />
          <ToastCard />
        </SnackbarProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
