import React from "react";

const ContactInformation = () => {
  return (
    <div className="p-8 bg-white text-gray-800">
      <h1 className="text-3xl font-bold mb-6">Contact Information</h1>
      <p className="mb-6">
        We appreciate your interest in The Everyone Platform. Our team is here
        to answer any questions you may have. Please reach out to us through any
        of the following channels:
      </p>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Investor Relations</h2>
        <p className="mb-2">
          For investment inquiries, partnership opportunities, or detailed
          information on Everyone’s growth strategy, please contact our Investor
          Relations team directly:
        </p>
        <p className="text-blue-600">Email: investors@everyone.world</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">General Inquiries</h2>
        <p className="mb-2">
          For general questions or more information about our platform, please
          connect with our support team:
        </p>
        <p className="text-blue-600">Email: info@everyone.world</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Office Location</h2>
        <p>The Everyone Platform Headquarters</p>
        <p>1001 Wilshire Blvd.</p>
        <p>Los Angeles, CA, 90017</p>
        <p>United States</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Social Media</h2>
        <p className="mb-2">
          Stay connected with us for updates and announcements:
        </p>
        <ul className="list-disc list-inside">
          <li>LinkedIn</li>
          <li>Twitter</li>
          <li>Facebook</li>
        </ul>
      </section>

      <p>We look forward to connecting with you.</p>
    </div>
  );
};

export default ContactInformation;
